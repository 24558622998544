<template>
  <div>
    <VAlert
      v-if="displayedRows.length === 0"
      :text="$t('app.no_completion_requests')"
    />

    <div v-else>
      <VCard
        v-for="(item, index) in displayedRows"
        :key="index"
        :class="{ 'mt-4': index !== 0 }"
      >
        <template #content>
          <div class="flex justify-between items-center">
            <div class="flex items-center">
              <VImage
                :src="item?.displayed_avatar"
                :name="item.displayed_name"
                class="mr-2"
              />

              <div>
                <div class="font-medium">
                  {{ item.displayed_name }}
                </div>

                <div class="font-light text-xs">
                  @{{ item?.displayed_username }}
                </div>
              </div>
            </div>

            <div>
              <VLine
                :label="$t('app.registration_requested_on')"
                :value="formatDate({ date: item?.registration_requested_on })"
              />
            </div>
          </div>
        </template>
      </VCard>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
// Composables
import useDisplay from "@/composables/useDisplay";
// Components
import VCard from "@/components/VCard";
import VLine from "@/components/VLine";
import VImage from "@/components/VImage";
import VAlert from "@/components/VAlert";

export default {
  components: {
    VCard,
    VImage,
    VAlert,
    VLine
  },
  props: {
    requests: {
      type: Array,
      default: () => []
    }
  },
  setup(props) {
    // Composables
    const { getAvatarURL, formatDate } = useDisplay();

    // Computed
    const displayedRows = computed(() => {
      return props.requests.map(request => ({
        ...request,
        displayed_avatar: getAvatarURL(request?.learner?.avatar?.view_path),
        displayed_name: `${request?.learner?.firstname} ${request?.learner?.lastname}`,
        displayed_username: request?.learner?.username
      }));
    });

    return {
      displayedRows,
      // useDisplay
      formatDate
    };
  }
};
</script>
