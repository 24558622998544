<template>
  <div>
    <div class="flex justify-between items-center">
      <VTitle :title="$t('app.requests', 2)" class="overview-title" />

      <div
        class="boxed-tabs nav nav-tabs bg-gray-200 text-gray-600 dark:bg-dark-1 dark:text-gray-500 rounded-md"
        role="tablist"
      >
        <VTab
          :name="VIEW.REGISTRATION"
          :label="$t('app.registrations', 2)"
          class="btn flex-1 border-0 shadow-none py-1.5 px-2 boxed-tab"
          lowercase-label
          :selected-tab="currentTab"
          @click="updateTab"
        />

        <VTab
          :name="VIEW.COMPLETION"
          :label="$t('app.completions', 2)"
          lowercase-label
          class="btn flex-1 border-0 shadow-none py-1.5 px-2 boxed-tab"
          :selected-tab="currentTab"
          @click="updateTab"
        />
      </div>
    </div>

    <RegistrationRequests
      v-if="isCurrentTab(VIEW.REGISTRATION)"
      :requests="registrationRequests"
    />

    <CompletionRequests
      v-if="isCurrentTab(VIEW.COMPLETION)"
      :requests="completionRequests"
    />
  </div>
</template>

<script>
// Composables
import useTabs from "@/composables/useTabs";
// Components
import VTab from "@/components/VTab";
import VTitle from "@/components/VTitle";
import CompletionRequests from "./CompletionRequests";
import RegistrationRequests from "./RegistrationRequests";

export default {
  components: {
    VTab,
    VTitle,
    RegistrationRequests,
    CompletionRequests
  },
  props: {
    completionRequests: {
      type: Array,
      default: () => []
    },
    registrationRequests: {
      type: Array,
      default: () => []
    }
  },
  setup() {
    // Constants
    const VIEW = {
      REGISTRATION: "registration",
      COMPLETION: "completion"
    };

    // Composables
    const { currentTab, updateTab, isCurrentTab } = useTabs(VIEW.REGISTRATION);

    return {
      VIEW,
      // useTabs
      currentTab,
      updateTab,
      isCurrentTab
    };
  }
};
</script>

<style scoped>
.boxed-tab {
  margin-right: 0px;
}
</style>
